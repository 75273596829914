import WorkFromHomeUserSofaStreamlineUltimate from "../assests/images/Work-From-Home-User-Sofa--Streamline-Ultimate.svg.png";
import StartupProductRocket from "../assests/images/Startup-Product-Rocket-Box--Streamline-Ultimate.svg.png";
import HumanResourcesNetwork from "../assests/images/Human-Resources-Network--Streamline-Ultimate.svg.png";
import HumanResourcesWorkflow from "../assests/images/Human-Resources-Workflow--Streamline-Ultimate.svg.png";
import Facebook from "../assests/images/Facebook-1--Streamline-Core.svg.png";
import Instagram from "../assests/images/Instagram-Logo--Streamline-Ultimate.svg.png";
import Linkedin from "../assests/images/Linkedin--Streamline-Core.svg.png";
import XLogo from "../assests/images/X-Logo-Light--Streamline-Phosphor.svg.png";
import Pinterest from "../assests/images/Pinterest--Streamline-Svg-Logos.svg.png";
import Icon1Image from "../assests/images/icon1.png";
import aboutUs2 from "../assests/images/aboutUs2.png";
import YoutubeClipLogoStreamlineUltimate from "../assests/images/Youtube-Clip-Logo--Streamline-Ultimate.svg.png";
import chooseUs from "../assests/images/chooseus2.svg";
import SilverIcon from "../assests/images/silverIcon.png";
import DiamondIcon from "../assests/images/dimondicon.png";
import GoldIcon from "../assests/images/goldIcon.png";
import PlatiniumIcon from "../assests/images/platiniumIcon.png";
import checkBox from "../assests/images/Check Box.png";
import CheckCricleImage from "../assests/images/Check circle.png";
import CrossCricleImage from "../assests/images/x-circle-contained.png";
import Testimonial1 from "../assests/images/testimonial-1.jpg";
import Testimonial2 from "../assests/images/testimonial-2.jpg";
import Testimonial3 from "../assests/images/testimonial-3.jpg";
import BlankCheckBox from "../assests/images/BlankCheckBox.png";
import AiArticleCoverImage from "../assests/images/AI-article-cover-image.jpg";
import LocalSeoTrends from "../assests/images/localseotrends.jpg";
import PpcCampaignCover from "../assests/images/ppc_campaign_cover.jpg";
import PayPerClickCover from "../assests/images/ppc_cover2.jpg";

import { options } from "./config";
export const quickLinks = [
  {
    name: "About",
    links: [
      {
        link: "/about",
        name: "About Us",
      },
      {
        link: "/contact",
        name: "Contact Us",
      },
    ],
  },
  {
    name: "Service",
    links: [
      {
        link: "/smo-services",
        name: "SMO Services",
      },
      {
        link: "/online-reputation-management",
        name: "Online Reputation",
      },

      {
        link: "/pay-per-click",
        name: "Pay Per Click",
      },
      {
        link: "/content-marketing",
        name: "Content Marketing",
      },
      {
        link: "/seo-services",
        name: "SEO Services",
      },
      {
        link: "/small-business-seo",
        name: "Small Business SEO",
      },
      {
        link: "/ecommerce-seo",
        name: "ECommerce SEO",
      },
      {
        link: "/local-seo-services",
        name: "Local SEO Services",
      },
      {
        link: "/white-label-seo",
        name: "White Label SEO",
      },
    ],
  },
  {
    name: "Pricing",
    links: [
      {
        link: "/seo",
        name: "SEO Pricing",
      },
      {
        link: "/smo",
        name: "SMO Pricing",
      },
      {
        link: "/ppc",
        name: "PPC Pricing",
      },
      {
        link: "/web-development",
        name: "Web Development",
      },
    ],
  },
  {
    name: "News",
    links: [
      {
        link: "/news/ai-in-seo",
        name: "AI In Seo",
      },
      {
        link: "/news/leading-seo-trends",
        name: "Leading Seo Trends",
      },
      {
        link: "/news/ppc-campaign",
        name: "Ppc Campaign",
      },
      {
        link: "/news/pay-per-click",
        name: "Pay Per Click",
      },
    ],
  },
];

export const Terms = [
  {
    link: "",
    name: "Terms of Service",
  },
  {
    link: "",
    name: "Privacy Policy",
  },
  {
    link: "",
    name: "Cookies",
  },
];

export const SeoRankingData = {
  icon: Icon1Image,
  heading: "Why Choose SEO Services for Your Business?",
  content: `Search Engine Optimization(SEO) is a fundamental component of any digital marketing strategy. It shows the search engine Who you are and why you matter. SEO features: link building, website optimization, keyword research, backlinking, and many more features. A good SEO strategy improves page ranking, brand awareness, and website traffic, increasing lead conversion and revenue.`,
  content2: `An SEO service company helps make a website market relevant and visible to the target audience. They can push you above your competitors so customers see you when they opt for the required product and service.`,
};

export const AgencyData = [
  {
    icon: WorkFromHomeUserSofaStreamlineUltimate,
    heading: "24x7 Exclusive Services",
    content:
      "Our connection with you is more important than our financial interests. Our 24/7 services are designed exclusively to help you with your business concerns.",
  },
  {
    icon: StartupProductRocket,
    heading: `Impressive Results`,
    content: `Since our main focus is achieving results, we create strategies to fulfill this objective without taking shortcuts for long-term gains.`,
  },
  {
    icon: HumanResourcesNetwork,
    heading: `Dedicated & Focused`,
    content: `Because of our dedication and single-minded focus, we will create new opportunities for your business and improve its overall performance.`,
  },
  {
    icon: HumanResourcesWorkflow,
    heading: `Trustworthy`,
    content: `At Ophanim, we understand the importance of trust in any business relationship. That's why we strive to establish a trustworthy and transparent relationship with our partners for long-term success.`,
  },
  {
    icon: HumanResourcesNetwork,
    heading: `Qualified and Professional`,
    content: `We have a fantastic team of experienced professionals qualified to thrive in any challenging environment and deliver high performance.`,
  },
  {
    icon: HumanResourcesWorkflow,
    heading: `Cutting Edge Tools`,
    content: `In today’s highly competitive market, we utilize cutting-edge tools and strategies relevant to the market trends.`,
  },
];

export const ServiceData = [
  {
    icon: YoutubeClipLogoStreamlineUltimate,
    heading: "YouTube Optimization",
    content:
      "As one of the most enjoyable and popular platforms, YouTube can boost your marketing plan, and we at Ophanim Technologies will make it possible for you to do so. We have a committed staff of Experienced YouTube Marketing experts to promote your videos on YouTube for greater exposure. Our YouTube SEO services allow you to concentrate on producing exceptional videos while we handle the daunting task of optimizing your YouTube channel. We will help you achieve:",
    tags: `More Engagement,Better Branding,More Subscribers,More visibility,Better Traction,Higher Conversion Rates`,
  },
  {
    icon: Facebook,
    heading: `Facebook Marketing`,
    content: `With over 2 billion active users, your target audience is undoubtedly present on Facebook. The market potential is substantial, and there is a high likelihood that you will encounter an audience that is intrigued by your offerings. Ophanim enables you to engage with customers directly and bridge the digital divide. The most effective solutions are presented to you by the resources and experience we possess.`,
    tags: `Targeting the Audience of Facebook Ads,Facebook Advertising Design,Facebook Ads Copywriting,A/B Testing of Facebook Ads,A/B Testing of Landing Pages,Optimization and Analysis`,
  },
  {
    icon: Instagram,
    heading: `Instagram Marketing`,
    content: `Instagram is an ideal platform for businesses to connect with their target audience, as more than one billion individuals utilize it monthly. However, establishing a brand requires effort, as competing with brands on a global scale is necessary. This is why businesses invest in Instagram marketing services from Ophanim. Our cutting-edge Instagram marketing team generates visually appealing reels and posts that will go viral on Instagram and attract millions of impressions and leads to your account. Our Instagram marketing services encompass the following:
`,
    tags: `Curation of the Instagram Calendar,Campaigns Set-up,Creating and Optimizing an Instagram Profile,Instagram Content Creation Instagram Postings and Promotions,Instagram advertising management`,
  },
  {
    icon: Linkedin,
    heading: `LinkedIn Marketing`,
    content: `LinkedIn is the optimal platform for connecting with professionals who are most likely to make critical business decisions. It boasts a conversion rate three times higher than that of any other ad platform. We offer LinkedIn Marketing services that target the entire sales funnel. We at Ophanim, possess a comprehensive expertise of LinkedIn's operations. We have recognized its usefulness and endeavour to enhance the number of users and your brand credibility most effectively.
 `,
    tags: `LinkedIn Advertising Optimization,LinkedIn Retargeting,LinkedIn Advertising Audits,LinkedIn Ad Management,Advertising Strategies on LinkedIn,Reporting on LinkedIn Ads`,
  },
  {
    icon: XLogo,
    heading: `X Marketing`,
    content: `X's millions of users are a treasure trove for traffic. It's unique features makes it a powerful tool for gaining audience insights and positioning your brand. Companies can even personalize their branding as per the trends. Ophanim assists your inbound marketing strategy by devising an effective content strategy. This strategy streamlines your content across multiple channels and reinforces your brand message in a fast, engaging, and enjoyable, such as in a tweet.
`,
    tags: `X remarketing campaigns,Creating X ads,Campaigns Set-up,X marketing,X campaign management,X ad monitoring`,
  },
  {
    icon: Pinterest,
    heading: `Pinterest Marketing`,
    content: `With Ophanim's extensive experience and proven track record, you can confidently expand your company’s brand and reach on social media. When you're looking to increase the income stream of your business, whether it's to boost sales, improve efficiency, or establish a brand image, we're here to assist. We'll ensure that only the most effective Pinterest marketing strategies are at your disposal, making the process efficient and the results achievable. Our Pinterest marketing services in the United States are among the most effective, and by collaborating with us and utilizing our services, you will be able to address the numerous persistent challenges.
`,
    tags: `Optimizing Pinterest Profile,Content Optimization,Scheduling Consistent Pins,Promotion of Pinterest Pins,Tracking Audience Engagement,Generate Pin-worthy Images,Increase Visual Appeal and Engagement`,
  },
];
export const HomeChooseUsData = [
  {
    icon: chooseUs,
    content:
      "Ophanim Technologies excels in digital marketing and has a dedicated team that stays updated on industry trends",
  },
  {
    icon: chooseUs,
    content: `Ophanim Technologies prioritizes delivering measurable results through data-driven campaigns, optimizing for maximum ROI and business growth.`,
  },
  {
    icon: chooseUs,
    content: `Tailored digital marketing strategies for businesses of all sizes, ensuring customized solutions for success.
`,
  },
  {
    icon: chooseUs,
    content: `Ophanim Technologies offers personalized support worldwide, focusing on understanding clients' needs for effective results.
 `,
  },
];

export const homeTopNotchData = [
  {
    icon: YoutubeClipLogoStreamlineUltimate,
    heading: "Search Engine Optimization",
    content: `Ophanim offers industry-proven SEO solutions tailored to your needs and keeps you at the top of the game. Our SEO experts have
                the expertise to increase your website traffic and enhance
                revenue.`,
  },
  {
    icon: YoutubeClipLogoStreamlineUltimate,
    heading: `Social Media Optimization`,
    content: `Increase your online visibility and connect with your target
                audience faster through social media platforms. Ophanim offers
                unparalleled SMO services that guarantee increased traffic,
                leads, and sales at a competitive rate.`,
  },
  {
    icon: YoutubeClipLogoStreamlineUltimate,
    heading: `Pay Per Click`,
    content: `Gain immediate and consistent traffic and leads by implementing
                optimized advertising campaigns. Ophanim's Pay-per-click
                marketing services will enable you to achieve immediate
                visibility on Google Ads.`,
  },
  {
    icon: YoutubeClipLogoStreamlineUltimate,
    heading: `Online Reputation Management`,
    content: `Boost your revenue by enhancing your online image and building
                customer trust. By proactively managing your online reputation,
                you can increase trust, leads, and sales.`,
  },
  {
    icon: YoutubeClipLogoStreamlineUltimate,
    heading: `ECommerce SEO`,
    content: `Enhance your revenue by leveraging eCommerce SEO services to
                connect with customers worldwide. Increase rankings, traffic,
                and sales for your online store.`,
  },
  {
    icon: YoutubeClipLogoStreamlineUltimate,
    heading: `Content Marketing`,
    content: `Google presents visitors with the most pertinent content based
                on their search intent. By providing high-quality content
                relevant to your audience, your business can enhance engagement
                and conversions.`,
  },
];

export const ExpertiseData = [
  {
    name: `Analysis and Goal Setting`,
  },
  {
    name: `Choosing the Right Communication Platform`,
  },
  {
    name: `Profile Optimization`,
  },
  {
    name: `Building a Strong Online Presence`,
  },
  {
    name: `Community Engagement`,
  },
  {
    name: `Monitoring and Improving Performance`,
  },
];

export const ContactInfoDetails = [
  {
    heading: "Email Address",
    content: "info@ophanimtechnologies.com",
    time: "Monday - Friday 10 am to 7 pm EST",
  },
  {
    heading: "Number",
    content: "+1 307 357 3070",
    time: "",
  },
];

export const pricingDetails = {
  WEB: [
    {
      type: options.PricingCategory.SILVER,
      price: "$599",
      tagline: "Essential Services",
      lists: [
        [
          {
            text: "Design Partially Custom – only 1 iteration",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Layered Sliders - 05",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Responsive Site (Desktop, Tablet, Mobile)",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "New Logo Design",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "HTML Email Templates",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Letterhead Design",
            icon: CrossCricleImage,
            isHeading: false,
          },
        ],
        [
          {
            text: "CMS WordPress / Joomla! / C5 /Drupal",
            icon: CrossCricleImage,
            isHeading: true,
          },
          {
            text: "Custom Blog",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "CMS Updates",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Front-End User Management",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Back-End User Management",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Social Logins",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Added Site Security",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "GUI Admin Panel",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Shopping Cart",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Dealer/ Affliate Section",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Search Engine Friendly Structure",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Newsletter Module",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Google eCommerce Tracking",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Conversion Forms-up to 3",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Content Migration",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Products Migration",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Order Migration",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "User Migration",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "New Domain (optional)",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Web Hosting",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Hosted Emails-10",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Web maintenance work-5 hours/month",
            icon: CheckCricleImage,
            isHeading: false,
          },
        ],
      ],
    },
    {
      type: options.PricingCategory.GOLD,
      price: "$999",
      tagline: "Essential Services",
      lists: [
        [
          {
            text: "Design Partially Custom – only 2 iteration",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Layered Sliders - 05",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Responsive Site (Desktop, Tablet, Mobile)",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "New Logo Design",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "HTML Email Templates",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Letterhead Design",
            icon: CrossCricleImage,
            isHeading: false,
          },
        ],
        [
          {
            text: "CMS WordPress / Joomla! / C5 / Drupal / Magento / Prestashop / Shopify / BigCommerce",
            icon: CrossCricleImage,
            isHeading: true,
          },
          {
            text: "Custom Blog",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "CMS Updates",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Front-End User Management",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Back-End User Management",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Social Logins",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Added Site Security",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "GUI Admin Panel",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Shopping Cart",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Dealer/ Affliate Section",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Search Engine Friendly Structure",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Newsletter Module",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Google eCommerce Tracking",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Conversion Forms-up to 3",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Content Migration",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Products Migration-upto 300",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Order Migration",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "User Migration",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "New Domain (optional)",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Web Hosting",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Hosted Emails-18",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Web maintenance work-10 hours/month",
            icon: CheckCricleImage,
            isHeading: false,
          },
        ],
      ],
    },
    {
      type: options.PricingCategory.PREMIUM,
      price: "$1599",
      tagline: "Essential Services",
      lists: [
        [
          {
            text: "Design Partially Custom – only 2 iteration",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Layered Sliders - 05",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Responsive Site (Desktop, Tablet, Mobile)",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "New Logo Design",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "HTML Email Templates",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Letterhead Design",
            icon: CheckCricleImage,
            isHeading: false,
          },
        ],
        [
          {
            text: "CMS WordPress / Joomla! / C5 / Drupal / Magento / Prestashop / Shopify / BigCommerce",
            icon: CheckCricleImage,
            isHeading: true,
          },
          {
            text: "Custom Blog",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "CMS Updates",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Front-End User Management",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Back-End User Management",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Social Logins",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Added Site Security",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "GUI Admin Panel",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Shopping Cart",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Dealer/ Affliate Section",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Search Engine Friendly Structure",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Newsletter Module",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Google eCommerce Tracking",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Conversion Forms-up to 3",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Content Migration",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Products Migration",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Order Migration",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "User Migration",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "New Domain (optional)",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Web Hosting",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Hosted Emails-30",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Web maintenance work-25 hours/month",
            icon: CheckCricleImage,
            isHeading: false,
          },
        ],
      ],
    },
  ],
  SEO: [
    {
      type: options.PricingCategory.SILVER,
      price: "$599",
      tagline: "Essential Services",
      lists: [
        [
          {
            text: "No. of Keywords – 30",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Landing Page- 05",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: " Backlinks Per Month - 120",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "GBP (GMB)",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Geotagging",
            icon: CrossCricleImage,
            isHeading: false,
          },
        ],
        [
          {
            text: "FIRST HAND SEO ANALYSIS",
            icon: CheckCricleImage,
            isHeading: true,
          },
          {
            text: "Pre-Optimization Website",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Competitor Analysis",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Keyword Research & Analysis",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: " Baseline Ranking Check",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Duplicate Content Check",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Google Penalty Check",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Backlink Analysis (if required)",
            icon: CrossCricleImage,
            isHeading: false,
          },
        ],
        [
          {
            text: "ON-SITE OPTIMIZATION ACTIVITIES",
            icon: CheckCricleImage,
            isHeading: true,
          },
          {
            text: "Title Tag Optimization",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "META Description Optimization",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Website Canonical Tags",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Heading Tags Optimization",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: " Image Alt Tags Optimization",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Content Optimization",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "SEO Friendly URL Setup",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Site Navigation Analysis",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "404 Page Implementation",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Broken Links Check",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Website Speed Check",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Google Indexed Pages Check",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Google XML Sitemap",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Robots.txt Creation",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Google Analytics Setup",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Google Webmasters Tool Setup",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Structured Data Setup",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "On Site Blog Section Creation",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "On Site Blog Posting - 1",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Setup of Website HTML Sitemap",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Keyword Mapping To Target Pages",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Initial External Link Analysis",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "linking Restructuring & Optimization",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Meta Robots Tags",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Language Tags Open Graph Tags",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Twitter Tags Email id Clickable",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Phone Number Clickable",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Copyright Tags implementation",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Copyright Years Updating Favicon Implementation Social Media Icon Setup",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Schema Implementation",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Google Analytics traffic analysis",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Ongoing quarterly keyword ranking reporting",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Ongoing user experience reporting (bounce rate, device category, etc.)",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: " Ongoing quarterly competitor conquest reports",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Google Business Profile optimization",
            icon: CheckCricleImage,
            isHeading: false,
          },
        ],
        [
          {
            text: "OFF-PAGE OPTIMIZATION AND LINK BUILDING",
            icon: CheckCricleImage,
            isHeading: true,
          },
          {
            text: "Search Engine Submission (One Time Task)-10",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: " Article Writing-1",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: " Article Posting-1",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Article Bookmarking-10",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Classified Submissions-10",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Blog Writing-1",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Blog Posting-1",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Blog Bookmarking-10",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Business Listing-4",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: " Image sharing-5",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "PPT Submissions-1",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Social Bookmarking-20",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "PDF Sharing-1",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Profile Creation-4",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Quora Answering-1",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Video Marketing (if Client provides)",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Press Release Writing-(N/A)",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Press Release Distribution-(N/A)",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Press Release Promotion-(N/A)",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Guest Blog Outreach-(N/A)",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Guest Blog Promotion-(N/A)",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Premium Press Release Writing-(N/A)",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Premium Press Release Distribution-(N/A)",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Premium Press Release Promotion",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Press Release (if Client provides)",
            icon: CheckCricleImage,
            isHeading: false,
          },
        ],
        [
          {
            text: "FACEBOOK",
            icon: CheckCricleImage,
            isHeading: true,
          },
          {
            text: "Facebook Profile Creation",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Facebook Fan Page Creation",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Facebook Posting & Sharing",
            icon: CrossCricleImage,
            isHeading: false,
          },
        ],
        [
          {
            text: "INSTAGRAM",
            icon: CheckCricleImage,
            isHeading: true,
          },
          {
            text: "Instagram Profile Creation",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Instagram Posting & Sharing",
            icon: CrossCricleImage,
            isHeading: false,
          },
        ],
        [
          {
            text: "TWITTER",
            icon: CheckCricleImage,
            isHeading: true,
          },
          {
            text: "Twitter Profile Creation",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Twitter Post",
            icon: CrossCricleImage,
            isHeading: false,
          },
        ],
        [
          {
            text: "PINTEREST",
            icon: CrossCricleImage,
            isHeading: true,
          },
          {
            text: "Account Creation/Management",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Pinterest Followers",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: " Updating of pin boards",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Pins (If Client Provides Images)",
            icon: CrossCricleImage,
            isHeading: false,
          },
        ],
        [
          {
            text: "MONTHLY REPORTS",
            icon: CheckCricleImage,
            isHeading: true,
          },
          {
            text: "Monthly Keywords Ranking Report",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Monthly Website Analytics Report",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: " Monthly Off Page Submission Report",
            icon: CheckCricleImage,
            isHeading: false,
          },
        ],
        [
          {
            text: "CUSTOMER SUPPORT",
            icon: CheckCricleImage,
            isHeading: true,
          },
          {
            text: "Email",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Phone",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Chat",
            icon: CheckCricleImage,
            isHeading: false,
          },
        ],
      ],
    },
    {
      type: options.PricingCategory.GOLD,
      price: "$899",
      tagline: "Essential Services",
      lists: [
        [
          {
            text: "No. of Keywords – 40",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Landing Page- 10",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: " Backlinks Per Month - 210",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "GBP (GMB)",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Geotagging",
            icon: CrossCricleImage,
            isHeading: false,
          },
        ],
        [
          {
            text: "FIRST HAND SEO ANALYSIS",
            icon: CheckCricleImage,
            isHeading: true,
          },
          {
            text: "Pre-Optimization Website",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Competitor Analysis",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Keyword Research & Analysis",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: " Baseline Ranking Check",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Duplicate Content Check",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Google Penalty Check",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Backlink Analysis (if required)",
            icon: CrossCricleImage,
            isHeading: false,
          },
        ],
        [
          {
            text: "ON-SITE OPTIMIZATION ACTIVITIES",
            icon: CheckCricleImage,
            isHeading: true,
          },
          {
            text: "Title Tag Optimization",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "META Description Optimization",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Website Canonical Tags",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Heading Tags Optimization",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: " Image Alt Tags Optimization",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Content Optimization",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "SEO Friendly URL Setup",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Site Navigation Analysis",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "404 Page Implementation",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Broken Links Check",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Website Speed Check",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Google Indexed Pages Check",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Google XML Sitemap",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Robots.txt Creation",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Google Analytics Setup",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Google Webmasters Tool Setup",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Structured Data Setup",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "On Site Blog Section Creation",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "On Site Blog Posting - 2",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Setup of Website HTML Sitemap",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Keyword Mapping To Target Pages",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Initial External Link Analysis",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "linking Restructuring & Optimization",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Meta Robots Tags",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Language Tags Open Graph Tags",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Twitter Tags Email id Clickable",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Phone Number Clickable",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Copyright Tags implementation",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Copyright Years Updating Favicon Implementation Social Media Icon Setup",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Schema Implementation",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Google Analytics traffic analysis",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Ongoing quarterly keyword ranking reporting",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Ongoing user experience reporting (bounce rate, device category, etc.)",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: " Ongoing quarterly competitor conquest reports",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Google Business Profile optimization",
            icon: CheckCricleImage,
            isHeading: false,
          },
        ],
        [
          {
            text: "OFF-PAGE OPTIMIZATION AND LINK BUILDING",
            icon: CheckCricleImage,
            isHeading: true,
          },
          {
            text: "Search Engine Submission (One Time Task)-20",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: " Article Writing-2",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: " Article Posting-2",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Article Bookmarking-20",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Classified Submissions-15",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Blog Writing-2",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Blog Posting-2",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Blog Bookmarking-20",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Business Listing-6",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: " Image sharing-10",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "PPT Submissions-1",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Social Bookmarking-30",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "PDF Sharing-2",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Profile Creation-6",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Quora Answering-2",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Video Marketing (if Client provides)",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Press Release Writing-(N/A)",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Press Release Distribution-(N/A)",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Press Release Promotion-(N/A)",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Guest Blog Outreach-(N/A)",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Guest Blog Promotion-(N/A)",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Premium Press Release Writing-(N/A)",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Premium Press Release Distribution-(N/A)",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Premium Press Release Promotion",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Press Release (if Client provides)",
            icon: CheckCricleImage,
            isHeading: false,
          },
        ],
        [
          {
            text: "FACEBOOK",
            icon: CheckCricleImage,
            isHeading: true,
          },
          {
            text: "Facebook Profile Creation",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Facebook Fan Page Creation",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Facebook Posting & Sharing - 4",
            icon: CheckCricleImage,
            isHeading: false,
          },
        ],
        [
          {
            text: "INSTAGRAM",
            icon: CheckCricleImage,
            isHeading: true,
          },
          {
            text: "Instagram Profile Creation",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Instagram Posting & Sharing - 4",
            icon: CheckCricleImage,
            isHeading: false,
          },
        ],
        [
          {
            text: "TWITTER",
            icon: CheckCricleImage,
            isHeading: true,
          },
          {
            text: "Twitter Profile Creation",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Twitter Post - 4",
            icon: CheckCricleImage,
            isHeading: false,
          },
        ],
        [
          {
            text: "PINTEREST",
            icon: CheckCricleImage,
            isHeading: true,
          },
          {
            text: "Account Creation/Management",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Pinterest Followers",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: " Updating of pin boards",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Pins (If Client Provides Images)",
            icon: CrossCricleImage,
            isHeading: false,
          },
        ],
        [
          {
            text: "MONTHLY REPORTS",
            icon: CheckCricleImage,
            isHeading: true,
          },
          {
            text: "Monthly Keywords Ranking Report",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Monthly Website Analytics Report",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: " Monthly Off Page Submission Report",
            icon: CheckCricleImage,
            isHeading: false,
          },
        ],
        [
          {
            text: "CUSTOMER SUPPORT",
            icon: CheckCricleImage,
            isHeading: true,
          },
          {
            text: "Email",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Phone",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Chat",
            icon: CheckCricleImage,
            isHeading: false,
          },
        ],
      ],
    },
    {
      type: options.PricingCategory.PLATINUM,
      price: "$1199",
      tagline: "Essential Services",
      lists: [
        [
          {
            text: "No. of Keywords – 50",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Landing Page - 15",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: " Backlinks Per Month - 300",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "GBP (GMB)",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Geotagging",
            icon: CheckCricleImage,
            isHeading: false,
          },
        ],
        [
          {
            text: "FIRST HAND SEO ANALYSIS",
            icon: CheckCricleImage,
            isHeading: true,
          },
          {
            text: "Pre-Optimization Website",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Competitor Analysis",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Keyword Research & Analysis",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: " Baseline Ranking Check",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Duplicate Content Check",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Google Penalty Check",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Backlink Analysis (if required)",
            icon: CheckCricleImage,
            isHeading: false,
          },
        ],
        [
          {
            text: "ON-SITE OPTIMIZATION ACTIVITIES",
            icon: CheckCricleImage,
            isHeading: true,
          },
          {
            text: "Title Tag Optimization",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "META Description Optimization",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Website Canonical Tags",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Heading Tags Optimization",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: " Image Alt Tags Optimization",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Content Optimization",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "SEO Friendly URL Setup",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Site Navigation Analysis",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "404 Page Implementation",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Broken Links Check",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Website Speed Check",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Google Indexed Pages Check",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Google XML Sitemap",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Robots.txt Creation",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Google Analytics Setup",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Google Webmasters Tool Setup",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Structured Data Setup",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "On Site Blog Section Creation",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "On Site Blog Posting - 3",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Setup of Website HTML Sitemap",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Keyword Mapping To Target Pages",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Initial External Link Analysis",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "linking Restructuring & Optimization",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Meta Robots Tags",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Language Tags Open Graph Tags",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Twitter Tags Email id Clickable",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Phone Number Clickable",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Copyright Tags implementation",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Copyright Years Updating Favicon Implementation Social Media Icon Setup",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Schema Implementation",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Google Analytics traffic analysis",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Ongoing quarterly keyword ranking reporting",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Ongoing user experience reporting (bounce rate, device category, etc.)",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: " Ongoing quarterly competitor conquest reports",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Google Business Profile optimization",
            icon: CheckCricleImage,
            isHeading: false,
          },
        ],
        [
          {
            text: "OFF-PAGE OPTIMIZATION AND LINK BUILDING",
            icon: CheckCricleImage,
            isHeading: true,
          },
          {
            text: "Search Engine Submission (One Time Task) - 30",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: " Article Writing - 3",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: " Article Posting - 3",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Article Bookmarking - 30",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Classified Submissions - 20",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Blog Writing - 3",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Blog Posting - 3",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Blog Bookmarking - 30",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Business Listing - 8",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: " Image sharing - 15",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "PPT Submissions - 3",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Social Bookmarking - 40",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "PDF Sharing - 3",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Profile Creation - 8",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Quora Answering - 3",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Video Marketing (if Client provides)",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Press Release Writing-(N/A)",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Press Release Distribution-(N/A)",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Press Release Promotion-(N/A)",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Guest Blog Outreach-(N/A)",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Guest Blog Promotion-(N/A)",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Premium Press Release Writing-(N/A)",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Premium Press Release Distribution-(N/A)",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Premium Press Release Promotion",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Press Release (if Client provides)",
            icon: CheckCricleImage,
            isHeading: false,
          },
        ],
        [
          {
            text: "FACEBOOK",
            icon: CheckCricleImage,
            isHeading: true,
          },
          {
            text: "Facebook Profile Creation",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Facebook Fan Page Creation",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Facebook Posting & Sharing-8",
            icon: CheckCricleImage,
            isHeading: false,
          },
        ],
        [
          {
            text: "INSTAGRAM",
            icon: CheckCricleImage,
            isHeading: true,
          },
          {
            text: "Instagram Profile Creation",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Instagram Posting & Sharing-8",
            icon: CheckCricleImage,
            isHeading: false,
          },
        ],
        [
          {
            text: "TWITTER",
            icon: CheckCricleImage,
            isHeading: true,
          },
          {
            text: "Twitter Profile Creation",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Twitter Post-8",
            icon: CheckCricleImage,
            isHeading: false,
          },
        ],
        [
          {
            text: "PINTEREST",
            icon: CheckCricleImage,
            isHeading: true,
          },
          {
            text: "Account Creation/Management",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Pinterest Followers",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: " Updating of pin boards-6",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Pins (If Client Provides Images)",
            icon: CheckCricleImage,
            isHeading: false,
          },
        ],
        [
          {
            text: "MONTHLY REPORTS",
            icon: CheckCricleImage,
            isHeading: true,
          },
          {
            text: "Monthly Keywords Ranking Report",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Monthly Website Analytics Report",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: " Monthly Off Page Submission Report",
            icon: CheckCricleImage,
            isHeading: false,
          },
        ],
        [
          {
            text: "CUSTOMER SUPPORT",
            icon: CheckCricleImage,
            isHeading: true,
          },
          {
            text: "Email",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Phone",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Chat",
            icon: CheckCricleImage,
            isHeading: false,
          },
        ],
      ],
    },
    {
      type: options.PricingCategory.DIAMOND,
      price: "$1999",
      tagline: "Essential Services",
      lists: [
        [
          {
            text: "No. of Keywords – 100",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Landing Page - 20",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: " Backlinks Per Month - 600",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "GBP (GMB)",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Geotagging",
            icon: CheckCricleImage,
            isHeading: false,
          },
        ],
        [
          {
            text: "FIRST HAND SEO ANALYSIS",
            icon: CheckCricleImage,
            isHeading: true,
          },
          {
            text: "Pre-Optimization Website",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Competitor Analysis",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Keyword Research & Analysis",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: " Baseline Ranking Check",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Duplicate Content Check",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Google Penalty Check",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Backlink Analysis (if required)",
            icon: CheckCricleImage,
            isHeading: false,
          },
        ],
        [
          {
            text: "ON-SITE OPTIMIZATION ACTIVITIES",
            icon: CheckCricleImage,
            isHeading: true,
          },
          {
            text: "Title Tag Optimization",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "META Description Optimization",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Website Canonical Tags",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Heading Tags Optimization",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: " Image Alt Tags Optimization",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Content Optimization",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "SEO Friendly URL Setup",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Site Navigation Analysis",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "404 Page Implementation",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Broken Links Check",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Website Speed Check",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Google Indexed Pages Check",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Google XML Sitemap",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Robots.txt Creation",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Google Analytics Setup",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Google Webmasters Tool Setup",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Structured Data Setup",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "On Site Blog Section Creation",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "On Site Blog Posting - 4",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Setup of Website HTML Sitemap",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Keyword Mapping To Target Pages",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Initial External Link Analysis",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "linking Restructuring & Optimization",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Meta Robots Tags",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Language Tags Open Graph Tags",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Twitter Tags Email id Clickable",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Phone Number Clickable",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Copyright Tags implementation",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Copyright Years Updating Favicon Implementation Social Media Icon Setup",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Schema Implementation",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Google Analytics traffic analysis",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Ongoing quarterly keyword ranking reporting",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Ongoing user experience reporting (bounce rate, device category, etc.)",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: " Ongoing quarterly competitor conquest reports",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Google Business Profile optimization",
            icon: CheckCricleImage,
            isHeading: false,
          },
        ],
        [
          {
            text: "OFF-PAGE OPTIMIZATION AND LINK BUILDING",
            icon: CheckCricleImage,
            isHeading: true,
          },
          {
            text: "Search Engine Submission (One Time Task) - 40",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: " Article Writing - 4",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: " Article Posting - 4",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Article Bookmarking - 40",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Classified Submissions - 25",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Blog Writing - 4",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Blog Posting - 4",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Blog Bookmarking - 40",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Business Listing - 10",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: " Image sharing - 20",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "PPT Submissions - 4",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Social Bookmarking - 50",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "PDF Sharing - 4",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Profile Creation - 10",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Quora Answering - 4",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Video Marketing (if Client provides)",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Press Release Writing",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Press Release Distribution",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Press Release Promotion",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Guest Blog Outreach",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Guest Blog Promotion",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Premium Press Release Writing-(N/A)",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Premium Press Release Distribution-(N/A)",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Premium Press Release Promotion",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Press Release (if Client provides)",
            icon: CheckCricleImage,
            isHeading: false,
          },
        ],
        [
          {
            text: "FACEBOOK",
            icon: CheckCricleImage,
            isHeading: true,
          },
          {
            text: "Facebook Profile Creation",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Facebook Fan Page Creation",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Facebook Posting & Sharing - 16",
            icon: CheckCricleImage,
            isHeading: false,
          },
        ],
        [
          {
            text: "INSTAGRAM",
            icon: CheckCricleImage,
            isHeading: true,
          },
          {
            text: "Instagram Profile Creation",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Instagram Posting & Sharing - 16",
            icon: CheckCricleImage,
            isHeading: false,
          },
        ],
        [
          {
            text: "TWITTER",
            icon: CheckCricleImage,
            isHeading: true,
          },
          {
            text: "Twitter Profile Creation",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Twitter Post - 12",
            icon: CheckCricleImage,
            isHeading: false,
          },
        ],
        [
          {
            text: "PINTEREST",
            icon: CheckCricleImage,
            isHeading: true,
          },
          {
            text: "Account Creation/Management",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Pinterest Followers",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: " Updating of pin boards - 12",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Pins (If Client Provides Images)",
            icon: CheckCricleImage,
            isHeading: false,
          },
        ],
        [
          {
            text: "MONTHLY REPORTS",
            icon: CheckCricleImage,
            isHeading: true,
          },
          {
            text: "Monthly Keywords Ranking Report",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Monthly Website Analytics Report",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: " Monthly Off Page Submission Report",
            icon: CheckCricleImage,
            isHeading: false,
          },
        ],
        [
          {
            text: "CUSTOMER SUPPORT",
            icon: CheckCricleImage,
            isHeading: true,
          },
          {
            text: "Email",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Phone",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Chat",
            icon: CheckCricleImage,
            isHeading: false,
          },
        ],
      ],
    },
  ],
  SMO: [
    {
      type: options.PricingCategory.SILVER,
      price: "$298",
      tagline: "Essential Services",
      lists: [
        [
          {
            text: " Competitor Analysis",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Strategy Formation",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: " Hashtag Creation And Promotion",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Monthly Creative Creation - 4",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Monthly Postings - 4",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Keywords",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Negative Keywords",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Engagement With Active Communities And Groups",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Network Build-Up",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Content Optimization",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: " Analysis Of Social Media Set-Up",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Posts Analysis",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Audience Behaviour Analysis",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Recommendations",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Building Social Media Strategy",
            icon: CheckCricleImage,
            isHeading: false,
          },
        ],
        [
          {
            text: "PAID PROMOTION",
            icon: CheckCricleImage,
            isHeading: true,
          },
          {
            text: "Identifying Suitable Objectives",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: " Identifying Target Audience",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Creation Of Ad Accounts",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Budget Estimate",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Setting Up Campaigns",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Ad Creative Creation",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Daily Account Optimization",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Setting Up Sales Funnel For Conversion Objective",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Conversion Tracking Assisted By Google Analytics",
            icon: CrossCricleImage,
            isHeading: false,
          },
        ],
        [
          {
            text: "REMARKETING",
            icon: CheckCricleImage,
            isHeading: true,
          },
          {
            text: "Identifying Suitable Objectives",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Identifying Target Audience",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Creation Of Ad Accounts",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Budget Estimate",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Setting Up Campaigns",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Ad Creative Creation",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Daily Account Optimization",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Setting Up Sales Funnel For Conversion Objective",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Conversion Tracking Assisted By Google Analytics",
            icon: CrossCricleImage,
            isHeading: false,
          },
        ],
        [
          {
            text: "CUSTOMER SUPPORT",
            icon: CheckCricleImage,
            isHeading: true,
          },
          {
            text: "Email",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Phone",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Chat",
            icon: CheckCricleImage,
            isHeading: false,
          },
        ],
      ],
    },
    {
      type: options.PricingCategory.GOLD,
      price: "$348",
      tagline: "Essential Services",
      lists: [
        [
          {
            text: " Competitor Analysis",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Strategy Formation",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: " Hashtag Creation And Promotion",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Monthly Creative Creation - 8",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Monthly Postings - 8",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Keywords",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Negative Keywords",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Engagement With Active Communities And Groups",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Network Build-Up",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Content Optimization",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: " Analysis Of Social Media Set-Up",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Posts Analysis",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Audience Behaviour Analysis",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Recommendations",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Building Social Media Strategy",
            icon: CheckCricleImage,
            isHeading: false,
          },
        ],
        [
          {
            text: "PAID PROMOTION",
            icon: CheckCricleImage,
            isHeading: true,
          },
          {
            text: "Identifying Suitable Objectives",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: " Identifying Target Audience",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Creation Of Ad Accounts",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Budget Estimate",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Setting Up Campaigns",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Ad Creative Creation",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Daily Account Optimization",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Setting Up Sales Funnel For Conversion Objective",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Conversion Tracking Assisted By Google Analytics",
            icon: CheckCricleImage,
            isHeading: false,
          },
        ],
        [
          {
            text: "REMARKETING",
            icon: CheckCricleImage,
            isHeading: true,
          },
          {
            text: "Identifying Suitable Objectives",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Identifying Target Audience",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Creation Of Ad Accounts",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Budget Estimate",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Setting Up Campaigns",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Ad Creative Creation",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Daily Account Optimization",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Setting Up Sales Funnel For Conversion Objective",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Conversion Tracking Assisted By Google Analytics",
            icon: CrossCricleImage,
            isHeading: false,
          },
        ],
        [
          {
            text: "CUSTOMER SUPPORT",
            icon: CheckCricleImage,
            isHeading: true,
          },
          {
            text: "Email",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Phone",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Chat",
            icon: CheckCricleImage,
            isHeading: false,
          },
        ],
      ],
    },
    {
      type: options.PricingCategory.PLATINUM,
      price: "$498",
      tagline: "Essential Services",
      lists: [
        [
          {
            text: " Competitor Analysis",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Strategy Formation",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: " Hashtag Creation And Promotion",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Monthly Creative Creation - 16",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Monthly Postings - 16",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Keywords",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Negative Keywords",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Engagement With Active Communities And Groups",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Network Build-Up",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Content Optimization",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: " Analysis Of Social Media Set-Up",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Posts Analysis",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Audience Behaviour Analysis",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Recommendations",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Building Social Media Strategy",
            icon: CheckCricleImage,
            isHeading: false,
          },
        ],
        [
          {
            text: "PAID PROMOTION",
            icon: CheckCricleImage,
            isHeading: true,
          },
          {
            text: "Identifying Suitable Objectives",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: " Identifying Target Audience",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Creation Of Ad Accounts",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Budget Estimate",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Setting Up Campaigns",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Ad Creative Creation",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Daily Account Optimization",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Setting Up Sales Funnel For Conversion Objective",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Conversion Tracking Assisted By Google Analytics",
            icon: CheckCricleImage,
            isHeading: false,
          },
        ],
        [
          {
            text: "REMARKETING",
            icon: CheckCricleImage,
            isHeading: true,
          },
          {
            text: "Identifying Suitable Objectives",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Identifying Target Audience",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Creation Of Ad Accounts",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Budget Estimate",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Setting Up Campaigns",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Ad Creative Creation",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Daily Account Optimization",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Setting Up Sales Funnel For Conversion Objective",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Conversion Tracking Assisted By Google Analytics",
            icon: CheckCricleImage,
            isHeading: false,
          },
        ],
        [
          {
            text: "CUSTOMER SUPPORT",
            icon: CheckCricleImage,
            isHeading: true,
          },
          {
            text: "Email",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Phone",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Chat",
            icon: CheckCricleImage,
            isHeading: false,
          },
        ],
      ],
    },
  ],
  PPC: [
    {
      type: options.PricingCategory.SILVER,
      price: "$299",
      tagline: "Essential Services",
      lists: [
        [
          {
            text: "Setup Fee - $199",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Advertising Budget – upto $999/month",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Full Optimizations – 1/month",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Ad Groups – Up To 5",
            icon: CheckCricleImage,
            isHeading: false,
          },
        ],
        [
          {
            text: "Silver Tasks",
            icon: CheckCricleImage,
            isHeading: true,
          },
          {
            text: "A/B Test",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Keywords Up To 50",
            icon: CheckCricleImage,
            isHeading: false,
          },
        ],
        [
          {
            text: "Negative Keywords",
            icon: CheckCricleImage,
            isHeading: true,
          },
          {
            text: "Conversion Tracking",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: " Monthly Reporting",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "No Contracts",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Ad Networks",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Google",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Yahoo",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Bing",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "FB",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Mobile Ads",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Call Extensions",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Sitelink Extensions",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Remarketing",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Monthly Conference Calls",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Display/Image Ads",
            icon: CrossCricleImage,
            isHeading: false,
          },
        ],
        [
          {
            text: "Client Support",
            icon: CheckCricleImage,
            isHeading: true,
          },
          {
            text: "Chat",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Email",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Phone",
            icon: CheckCricleImage,
            isHeading: false,
          },
        ],
      ],
    },
    {
      type: options.PricingCategory.GOLD,
      price: "$399",
      tagline: "Essential Services",
      lists: [
        [
          {
            text: "Setup Fee - $349",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Advertising Budget – upto $1999/month",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Full Optimizations – 2/month",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Ad Groups – Up To 10",
            icon: CheckCricleImage,
            isHeading: false,
          },
        ],
        [
          {
            text: "Gold Tasks",
            icon: CheckCricleImage,
            isHeading: true,
          },
          {
            text: "A/B Test",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Keywords Up To 100",
            icon: CheckCricleImage,
            isHeading: false,
          },
        ],
        [
          {
            text: "Negative Keywords",
            icon: CheckCricleImage,
            isHeading: true,
          },
          {
            text: "Conversion Tracking",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: " Monthly Reporting",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "No Contracts",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Ad Networks",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Google",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Yahoo",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Bing",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "FB",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Mobile Ads",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Call Extensions",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Sitelink Extensions",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Remarketing",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Monthly Conference Calls",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Display/Image Ads",
            icon: CrossCricleImage,
            isHeading: false,
          },
        ],
        [
          {
            text: "Client Support",
            icon: CheckCricleImage,
            isHeading: true,
          },
          {
            text: "Chat",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Email",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Phone",
            icon: CheckCricleImage,
            isHeading: false,
          },
        ],
      ],
    },
    {
      type: options.PricingCategory.PLATINUM,
      price: "$549",
      tagline: "Essential Services",
      lists: [
        [
          {
            text: "Setup Fee - $449",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Advertising Budget – upto $2999/month",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Full Optimizations – 3/month",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Ad Groups – Up To 20",
            icon: CheckCricleImage,
            isHeading: false,
          },
        ],
        [
          {
            text: "Platinum Tasks",
            icon: CheckCricleImage,
            isHeading: true,
          },
          {
            text: "A/B Test",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Keywords Up To 200",
            icon: CheckCricleImage,
            isHeading: false,
          },
        ],
        [
          {
            text: "Negative Keywords",
            icon: CheckCricleImage,
            isHeading: true,
          },
          {
            text: "Conversion Tracking",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: " Monthly Reporting",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "No Contracts",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Ad Networks",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Google",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Yahoo",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Bing",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "FB",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Mobile Ads",
            icon: CrossCricleImage,
            isHeading: false,
          },
          {
            text: "Call Extensions",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Sitelink Extensions",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Remarketing",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Monthly Conference Calls",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Display/Image Ads",
            icon: CrossCricleImage,
            isHeading: false,
          },
        ],
        [
          {
            text: "Client Support",
            icon: CheckCricleImage,
            isHeading: true,
          },
          {
            text: "Chat",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Email",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Phone",
            icon: CheckCricleImage,
            isHeading: false,
          },
        ],
      ],
    },
    {
      type: options.PricingCategory.DIAMOND,
      price: "$699",
      tagline: "Essential Services",
      lists: [
        [
          {
            text: "Setup Fee - $599",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Advertising Budget – upto $2999/month",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Full Optimizations – 5/month",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Ad Groups – Up To 40",
            icon: CheckCricleImage,
            isHeading: false,
          },
        ],
        [
          {
            text: "Diamond Tasks",
            icon: CheckCricleImage,
            isHeading: true,
          },
          {
            text: "A/B Test",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Keywords Up To 500",
            icon: CheckCricleImage,
            isHeading: false,
          },
        ],
        [
          {
            text: "Negative Keywords",
            icon: CheckCricleImage,
            isHeading: true,
          },
          {
            text: "Conversion Tracking",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: " Monthly Reporting",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "No Contracts",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Ad Networks",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Google",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Yahoo",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Bing",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "FB",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Mobile Ads",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Call Extensions",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Sitelink Extensions",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Remarketing",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Monthly Conference Calls",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Display/Image Ads",
            icon: CrossCricleImage,
            isHeading: false,
          },
        ],
        [
          {
            text: "Client Support",
            icon: CheckCricleImage,
            isHeading: true,
          },
          {
            text: "Chat",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Email",
            icon: CheckCricleImage,
            isHeading: false,
          },
          {
            text: "Phone",
            icon: CheckCricleImage,
            isHeading: false,
          },
        ],
      ],
    },
  ],
};

export const aboutUsData = {
  icon: aboutUs2,
  heading: "About us",
  content: `Ophanim Technologies is a leading digital platform that helps you outrank your competitors by providing top-notch Digital Marketing and Web Development services. We understand the business need to build a personalized brand, boost traffic, and create engaging web content. Our family of specialists provides top-performing strategies that rank, boost, and convert. We focus on building organic traffic and audience retention with high-ranking keywords and engaging content.`,
  content2: `Our work processes incorporate raw data tailored to current market trends and customer needs. With 100+ satisfied clients, we guarantee the highest ROI in the industry.`,
};

export const seoPlans = [
  {
    icon: SilverIcon,
    planName: "Silver",
    price: 599,
    features: [
      { icon: checkBox, text: "No. of Keywords - 30" },
      { icon: checkBox, text: "Landing Page - 05" },
      { icon: checkBox, text: "Backlinks Per Month - 120" },
      { icon: BlankCheckBox, text: "Geotagging", disabled: true },
      { icon: BlankCheckBox, text: "GBP (GMB)", disabled: true },
    ],
    isHighlighted: false,
  },
  {
    icon: GoldIcon,
    planName: "Gold",
    price: 899,
    features: [
      { icon: checkBox, text: "No. of Keywords - 40" },
      { icon: checkBox, text: "Landing Page - 10" },
      { icon: checkBox, text: "Backlinks Per Month - 210" },
      { icon: checkBox, text: "GBP (GMB)" },
      { icon: BlankCheckBox, text: "Geotagging", disabled: true },
    ],
    isHighlighted: true,
  },
  {
    icon: PlatiniumIcon,
    planName: "Platinum",
    price: 1199,
    features: [
      { icon: checkBox, text: "No. of Keywords - 50" },
      { icon: checkBox, text: "Landing Page - 15" },
      { icon: checkBox, text: "Backlinks Per Month - 300" },
      { icon: checkBox, text: "Geotagging" },
      { icon: checkBox, text: "GBP (GMB)" },
    ],
    isHighlighted: false,
  },
  {
    icon: DiamondIcon,
    planName: "Diamond",
    price: 1999,
    features: [
      { icon: checkBox, text: "No. of Keywords - 100" },
      { icon: checkBox, text: "Landing Page - 20" },
      { icon: checkBox, text: "Backlinks Per Month - 600" },
      { icon: checkBox, text: "Geotagging" },
      { icon: checkBox, text: "GBP (GMB)" },
    ],
    isHighlighted: false,
  },
];

export const pricingCategory = {
  WEB: "WEB",
  SEO: "SEO",
  SMO: "SMO",
  PPC: "PPC",
};

export const reviews = [
  {
    name: "Ashvini Arya",
    image: Testimonial1,
    review: `I've been working with Ophanim for several months now and have been extremely pleased with the results. Their link-building strategies have been instrumental in driving more traffic to our website.`,
    company: "Bhutishraj Pvt Ltd.",
  },
  {
    name: "Vansh Singh",
    image: Testimonial2,
    review: `My experiences with Ophanim Technologies have been fantastic. Mr. Ryan and his team were extremely friendly, helpful, and quick to respond. They went above and beyond to help us reach many of our objectives. I can't recommend them enough! `,
    company: "Pragyavan Pvt Ltd.",
  },
  {
    name: "Sandeep Choudhary",
    image: Testimonial3,
    review: `The team at Ophanim exceeded our expectations with their professional and efficient link-building services. Our website's search engine rankings have greatly improved as a result. Highly recommended!`,
    company: "Destin Services Pvt. Ltd.",
  },
];

export const pageTitleDescription = {
  HOME: {
    title: `Leading Digital Marketing & SEO Agency | Ophanim Technologies`,
    description: `Ophanim Technologies delivers measurable SEO results for enterprises and helps businesses achieve top search rankings and sustainable online success.`,
  },
  ABOUT_US: {
    title: `Ophanim Technologies: Digital Marketing Agency | About Us`,
    description: `Know more about Ophanim Technologies and how we craft data-driven strategies to elevate your search rankings and drive organic growth for your business.`,
  },
  SEO_SERVICES: {
    title: `Complete SEO Services for Your Business by Ophanim Technologies`,
    description: `Increase organic traffic, leads, and sales with our comprehensive SEO solutions. We help medium to large businesses in the USA & Canada achieve top search rankings.`,
  },
  SEO_PRICING: {
    title: `Seo Plan | Ophanim Technologies`,
    description: ``,
  },
  SMO_PRICING: {
    title: `Smo Plan | Ophanim Technologies`,
    description: ``,
  },
  PPC_PRICING: {
    title: `Ppc Plan | Ophanim Technologies`,
    description: ``,
  },
  WEB_PRICING: {
    title: `Web Development Plan | Ophanim Technologies`,
    description: ``,
  },
  NEWS: {
    title: `News | Ophanim Technologies`,
    description: ``,
  },
  CONTACT_US: {
    title: `Contact Us | Ophanim Technologies`,
    description: ``,
  },
};

export const ServicePagesData = {
  SEO_SERVICES: {
    pageHeader: {
      title: `Most In-Demand`,
      subTitle: `SEO Services in the USA`,
      tagline: `#1 SEO service provider company that helps you establish the online presence you deserve, ensuring your business stands out in the digital landscape.`,
    },
    first_section_icon: Icon1Image,
    first_section_heading: `Why Choose SEO Services for Your Business?`,
    first_section_content1: `Search Engine Optimization(SEO) is a fundamental component of any digital marketing strategy. It shows the search engine Who you are and why you matter. SEO features: link building, website optimization, keyword research, backlinking, and many more features. A good SEO strategy improves page ranking, brand awareness, and website traffic, increasing lead conversion and revenue.`,
    first_section_content2: `An SEO service company helps make a website market relevant and visible to the target audience. They can push you above your competitors so customers see you when they opt for the required product and service.`,
    second_section_heading: `Why Choose Ophanim for SEO Services in the USA?`,
    second_seaction_paragraph: `At Ophanim, we prioritize your success over money. Our goal is to
              help your business website reach its targets with ease. Each
              metric needs careful analysis. Seeking help from leading SEO
              experts is essential for higher rankings and gaining a competitive
              edge.`,
    agencyData: [
      {
        icon: WorkFromHomeUserSofaStreamlineUltimate,
        heading: "24x7 Exclusive Services",
        content:
          "Our connection with you is more important than our financial interests. Our 24/7 services are designed exclusively to help you with your business concerns.",
      },
      {
        icon: StartupProductRocket,
        heading: `Impressive Results`,
        content: `Since our main focus is achieving results, we create strategies to fulfill this objective without taking shortcuts for long-term gains.`,
      },
      {
        icon: HumanResourcesNetwork,
        heading: `Dedicated & Focused`,
        content: `Because of our dedication and single-minded focus, we will create new opportunities for your business and improve its overall performance.`,
      },
      {
        icon: HumanResourcesWorkflow,
        heading: `Trustworthy`,
        content: `At Ophanim, we understand the importance of trust in any business relationship. That's why we strive to establish a trustworthy and transparent relationship with our partners for long-term success.`,
      },
      {
        icon: HumanResourcesNetwork,
        heading: `Qualified and Professional`,
        content: `We have a fantastic team of experienced professionals qualified to thrive in any challenging environment and deliver high performance.`,
      },
      {
        icon: HumanResourcesWorkflow,
        heading: `Cutting Edge Tools`,
        content: `In today’s highly competitive market, we utilize cutting-edge tools and strategies relevant to the market trends.`,
      },
    ],
  },
  SMO_SERVICES: {
    pageHeader: {
      title: `Most In-Demand`,
      subTitle: `SMO Services in the USA`,
      tagline: `#1 SMO service provider company that helps enhance your online visibility.`,
    },
    first_section_icon: Icon1Image,
    first_section_heading: `Why Choose SMO Services for Your Business?`,
    first_section_content1: `Utilizing social media optimization involves leveraging different social platforms to boost your company's visibility online and optimizing business profiles on sites like Facebook, Twitter, and Instagram.`,
    first_section_content2: `By engaging Social Media Optimization services, you can increase your brand's reach on multiple social platforms, encourage user interaction, build trust, acquire customers, boost conversions, and drive sales.`,
    second_section_heading: `Why Choose Ophanim As Your Social Media Optimization Agency?`,
    second_seaction_paragraph: `Selecting the appropriate social media optimization services is crucial for the growth and success of your business. As a leading SMO company in the USA, we are proud to be the top choice for hundreds of companies. Learn why we are the go-to partner for SMO campaign success.`,
    third_section_heading: `Our SMO Services`,
    third_section_content: `Optimize your Social Media and drive customer engagement with our`,
    agencyData: [
      {
        icon: WorkFromHomeUserSofaStreamlineUltimate,
        heading: `Cost-Effective Solutions`,
        content: `Our tailored SMO services elevate your social media presence while fitting seamlessly into your budget. We aim to empower your business growth through enhanced social media engagement, increased quality traffic, and lead generation. We customize our social media optimization services to meet your unique needs and preferences.`,
      },
      {
        icon: StartupProductRocket,
        heading: `Custom Social Media Optimization Strategy`,
        content: `Your business requires a unique edge to compete with more giant corporations. To achieve this, we provide bespoke social media optimization services that cater to your needs. Rather than adopting a one-size-fits-all approach, we craft a tailored SMO marketing strategy that aligns with your objectives and resonates with your target demographic.`,
      },
      {
        icon: HumanResourcesNetwork,
        heading: `Google Certified Partner`,
        content: `Consider selecting a Google Partner-certified agency like ours to benefit from dependable SEO and SMO services. This certification demonstrates our expertise and proven success in achieving desired outcomes. Being a Google partner, we focus on SMO, search, mobile, and SMM, providing comprehensive solutions for your digital requirements.`,
      },
      {
        icon: HumanResourcesWorkflow,
        heading: `Broad Expertise Across Sectors`,
        content: `Our extensive experience spanning multiple industries allows us to leverage various technical expertise and know-how on every social media optimization project we undertake. Having collaborated with thousands of businesses across diverse sectors, our SMO specialists are well-equipped to tailor their services to meet your business's distinct requirements.`,
      },
      {
        icon: HumanResourcesNetwork,
        heading: `Comprehensive SMO Services`,
        content: `Our all-encompassing social media optimization services are designed to drive your online success. We offer a complete range of services, from devising a tailored strategy to implementing affordable social media, search engine optimization, digital marketing, and website optimization solutions, all aimed at delivering the outcomes necessary to propel your business forward.`,
      },
      {
        icon: HumanResourcesWorkflow,
        heading: `Clear Communication`,
        content: `As a leading SMO marketing agency, we value transparency in communication. Our dedicated project managers ensure you are informed at every stage, providing detailed insights into our actions and future direction.`,
      },
    ],
    serviceData: ServiceData,
  },
  ONLINE_REPUTATION: {
    pageHeader: {
      title: `#1`,
      subTitle: `ORM Services in the USA`,
      tagline: `Premier Online Reputation Management service provider company for streamlined online presence solutions.`,
    },
    first_section_icon: Icon1Image,
    first_section_heading: `How do Reputation Management Services help you Grow Your Revenue`,
    first_section_content1: `In today's digital age, online reviews hold more sway with customers than word-of-mouth endorsements. The internet's far-reaching influence allows unfavourable feedback to circulate rapidly, potentially tarnishing your business's online standing.`,
    first_section_content2: `Companies and individuals must invest significant resources in establishing a positive reputation, as this can be quickly tarnished by internet troublemakers or one's own mistakes. In the modern era, having a reputable online reputation management firm to oversee, enhance, and safeguard your brand is essential.`,
    second_section_heading: `Why Choose Ophanim As Your Online Reputation Management Agency`,
    second_seaction_paragraph: `Our organization is committed to excellence in every aspect of our operations. We tailor our approaches to meet your business's unique requirements while prioritizing optimizing your return on investment.`,
    agencyData: [
      {
        icon: WorkFromHomeUserSofaStreamlineUltimate,
        heading: `Skilled Team`,
        content: `We only hire the most talented and skilled individuals in their respective fields. This ensures that our performance is consistently exceptional and that our clients receive the highest-quality service possible.`,
      },
      {
        icon: StartupProductRocket,
        heading: `In-Depth Research and Analysis`,
        content: `With our cutting-edge approach, in-depth analysis, and extensive expertise in digital marketing, we are poised to elevate every facet of your online marketing strategy. Our pioneering spirit, rigorous research, and wealth of experience ensure that your campaign will flourish under our guidance.`,
      },
      {
        icon: HumanResourcesNetwork,
        heading: `Tailored ORM Approach`,
        content: `We recognize that your business requires unique strategies compared to other brands. Therefore, we provide personalized reputation management services. Our expertise lies in using customized ORM methods to enhance your brand reputation, increase website traffic, and generate leads that align with your brand objectives and target audience.`,
      },
      {
        icon: HumanResourcesWorkflow,
        heading: `Content Optimization Focus`,
        content: `Our primary emphasis is optimizing content to enhance your brand's online reputation through strategic amplification and development. By creating strong content tailored for search engines and promoting positive messaging on appropriate platforms, we work to bolster your brand's image and minimize negative associations.`,
      },
      {
        icon: HumanResourcesNetwork,
        heading: `Tools and Technologies`,
        content: `We utilize cutting-edge solutions tailored explicitly for reputation management, empowering us to craft data-driven strategies that drive optimal outcomes for your brand. By harnessing advanced keyword research tools and comprehensive analytics platforms, we refine our approaches to achieve unparalleled success.`,
      },
      {
        icon: HumanResourcesWorkflow,
        heading: `High Client Satisfaction`,
        content: `Exceptional Client Experience places paramount importance on client satisfaction, boasting a client retention rate of over 90%. We take pride in the trust and loyalty of our clients, and we invite you to peruse our client testimonials to see firsthand the results-driven approach and unwavering commitment we bring to every client engagement.`,
      },
    ],
  },
  SMALL_BUSINESS_SEO: {
    pageHeader: {
      title: `#1 Small Business SEO`,
      subTitle: `Services in the USA`,
      tagline: `#1 SEO service provider company that helps you establish the online presence you deserve, ensuring your business stands out in the digital landscape.`,
    },
    first_section_icon: Icon1Image,
    first_section_heading: `How Can Small Business SEO Services Increase Your Revenue?`,
    first_section_content1: `Small-business SEO refers to enhancing your small business's web presence on the search results. SEO services for small businesses can improve your revenue by increasing your visibility online, attracting the appropriate audience, and improving your overall digital presence.`,
    first_section_content2: ``,
    second_section_heading: `Why Consider Ophanim for Your Small Business SEO?`,
    second_seaction_paragraph: `The selection of appropriate small business SEO services is essential for the expansion of your business. We are honoured to be the preferred choice of hundreds of small and medium-sized businesses, as we are one of the most exceptional SEO companies for small businesses. Discover the reasons why numerous organizations select us for SEO success.`,
    agencyData: [
      {
        icon: WorkFromHomeUserSofaStreamlineUltimate,
        heading: `Reasonably Priced Plans`,
        content: `Our SEO services for small businesses are cost-effective, allowing you to enhance online visibility while adhering to your budget. Our objective is to assist you in growing your business by providing you with high-quality traffic and leads at a reasonable cost. Our small business SEO services are entirely customizable to ensure your convenience.`,
      },
      {
        icon: StartupProductRocket,
        heading: `Tailored Search engine optimization strategy`,
        content: `We recognize that your business's requirements are distinct from those of big brands. This is why we provide personalized SEO services. We refrain from employing generic strategies to enhance your online presence. Instead, we customize your SEO marketing campaign to align with your objectives and target audience.`,
      },
      {
        icon: HumanResourcesNetwork,
        heading: `Google Certified Partner`,
        content: `For dependable SEO services for small businesses, select a Google Partner-certified agency like ours. This certification demonstrates our proficiency and demonstrated history of achieving results. We are a Google premier partner specializing in search, mobile, video, display, and shopping advertising, providing comprehensive solutions for your digital advertising requirements.`,
      },
      {
        icon: HumanResourcesWorkflow,
        heading: `Proficiency in a Variety of Industries`,
        content: `Due to our diverse industry background, we can contribute a wide range of skills and experience to each SMB project we undertake. Our team of small business SEO services experts has experience working with thousands of businesses in all niches, including yours. You can trust us to meet your specific requirements.`,
      },
      {
        icon: HumanResourcesNetwork,
        heading: `One-Stop SEO Solutions`,
        content: `We offer comprehensive SEO solutions, including search engine optimization, social media management, content writing, and development services. Our goal is to assist you in achieving the SEO results necessary to expand your business.`,
      },
      {
        icon: HumanResourcesWorkflow,
        heading: `Transparent Reporting`,
        content: `Your dedicated account managers closely monitor and track your SEO campaign. They also provide you with regular updates on your website's online and offline activities. Our SEO reports enable you to monitor your SEO performance, including rankings, traffic, and conversions.`,
      },
    ],
  },
  ECOMMERCE_SEO: {
    pageHeader: {
      title: `Most in-demand`,
      subTitle: `E-Commerce SEO service`,
      tagline: `Most in-demand E-Commerce SEO service companies help you establish the online presence you deserve, ensuring your business stands out in the digital landscape.`,
    },
    first_section_icon: Icon1Image,
    first_section_heading: `Benefits of Employing an Ecommerce SEO Company`,
    first_section_content1: `Ecommerce SEO is optimizing your website to adhere to search engine best practices and updating your content to align with the search queries of your potential customers. Any ecommerce business prioritizes the acquisition of more targeted traffic.`,
    first_section_content2: `SEO professionals are proficient in the most recent technologies, tools, market trends, and intricate search engine algorithms to ensure your business remains at the forefront of the search engine results page (SERP). By employing an ecommerce SEO agency, you can allocate more time to your daily business operations. SEO services for ecommerce can increase revenue by improving digital presence, attracting the appropriate audience, and enhancing online visibility.`,
    second_section_heading: `Why Choose Ophanim for E-Commerce SEO Services?`,
    second_seaction_paragraph: `At Ophanim, we prioritize your success over money. Selecting the appropriate ecommerce SEO agency to facilitate your business's expansion. Discover the reasons why numerous organizations choose us for SEO success.`,
    agencyData: [
      {
        icon: WorkFromHomeUserSofaStreamlineUltimate,
        heading: `Comprehensive Strategy`,
        content: `Our comprehensive SEO ecommerce strategy guarantees that we address all facets. We customize on-page SEO to meet the specific requirements of your website, conduct a thorough analysis of its structure, and implement our tools accordingly. We comprehend eCommerce sites' distinctive challenges and complexities, including product listings and reviews.`,
      },
      {
        icon: StartupProductRocket,
        heading: `Targeted Approach`,
        content: `Our ecommerce SEO agency conducts exhaustive research to identify the most appropriate keywords rather than simply targeting any keyword. This guarantees that your website is accessible to individuals actively seeking your product. Our customized strategy guarantees that your messages are delivered to the appropriate audience, optimizing conversion and engagement opportunities.`,
      },
      {
        icon: HumanResourcesNetwork,
        heading: `Emphasis on Content Optimization`,
        content: `Our team is dedicated to developing, optimizing, and amplifying content. This guarantees that each article we distribute is precisely customized to resonate with your intended audience and search engine bots. We comprehend the necessity of content that is both engaging and on-brand and that encourages link-building, keyword ranking, and traffic growth.`,
      },
      {
        icon: HumanResourcesWorkflow,
        heading: `SEO Strategy and Reporting`,
        content: `We at the ecommerce SEO company recognize that the requirements of your business are distinct from those of other major brands. This is the reason we provide personalized SEO services for ecommerce. We refrain from employing generic strategies to enhance your online presence. Instead, we customize strategies to align with your objectives and target audience, as evidenced by our portfolio.`,
      },
      {
        icon: HumanResourcesNetwork,
        heading: `Client Satisfaction`,
        content: `We have consistently believed that our clients are our most valuable asset. Our dedication to providing exceptional service is evident in our client retention rate of over 90%. Our client testimonial is available for your review. The relationships and trust we establish daily are the true meaning of our dedication; it is not solely based on numbers.`,
      },
      {
        icon: HumanResourcesWorkflow,
        heading: `Reasonable Plans`,
        content: `Our cost-effective ecommerce SEO services allow your business to enhance its online visibility while adhering to your budget. Our objective is to assist you in the growth of your ecommerce business by generating high-quality traffic and leads at a minimal cost. Investigate an inexpensive SEO package for ecommerce at this time.`,
      },
    ],
  },
  LOCAL_SEO_SERVICES: {
    pageHeader: {
      title: `Most In-Demand`,
      subTitle: `SEO Services in the USA`,
      tagline: `Most in-demand Local SEO service company that helps you establish a robust online presence. Our local SEO services are available at a reasonable price and can increase the number of calls, leads, store visits, and sales.`,
    },
    first_section_icon: Icon1Image,
    first_section_heading: `Benefits of Employing an Local SEO Company`,
    first_section_content1: `Local SEO services increase revenue for your business by enhancing your local positioning and GMB listings, boosting local reviews, and strategically targeting customers who desire products and services within your service area.`,
    first_section_content2: ``,
    second_section_heading: `Why Choose Ophanim for Local SEO Services?`,
    second_seaction_paragraph: `One of the most critical actions to expand your business is to engage the services of the most qualified local SEO company. We are proud to be the preferred choice for hundreds of small and medium-sized businesses and are one of the top local SEO service providers. Discover the reasons why hundreds of businesses select us for their SEO success.`,
    agencyData: [
      {
        icon: WorkFromHomeUserSofaStreamlineUltimate,
        heading: `Local SEO Services of the Highest Quality`,
        content: `Are you seeking to distinguish yourself in local search results? Ophanim is committed to providing exceptional local SEO services. We are committed to enhancing the visibility of your business when customers in the vicinity are conducting searches for your products or services.`,
      },
      {
        icon: StartupProductRocket,
        heading: `Reasonably priced Local SEO services`,
        content: `Ophanim provides cost-effective solutions that yield results. Our affordable local SEO services are specifically engineered to empower small and medium-sized businesses, guaranteeing that you can improve your local online presence without exceeding your budget.`,
      },
      {
        icon: HumanResourcesNetwork,
        heading: `Local SEO Reports`,
        content: `Are you interested in the status of your local SEO campaign? We have you covered with our comprehensive reporting. Ophanim offers valuable information regarding your organization's performance in local searches. We are committed to maintaining transparency to enable you to observe the results of our endeavors.`,
      },
      {
        icon: HumanResourcesWorkflow,
        heading: `Local Industry Experience`,
        content: `Our team has a wealth of experience in navigating local markets. We know your local industry's distinctive obstacles and employ our expertise to create strategies that resonate with your target audience. You can be confident that your SEO campaign is in the capable hands of our local SEO company.`,
      },
      {
        icon: HumanResourcesNetwork,
        heading: `Local Digital Marketing Solutions`,
        content: `Your digital marketing solutions should be distinctive, just as your business is. In addition to providing exceptional local SEO services, we also address your comprehensive online marketing requirements, enabling you to develop customized strategies specifically designed to target your local audience. We guarantee that your digital initiatives align with the local community you serve.`,
      },
      {
        icon: HumanResourcesWorkflow,
        heading: `Transparent communication`,
        content: `Clear communication is essential for a partnership to be successful. That is why we emphasize the importance of transparent communication. Our local SEO package prioritizes your input throughout the SEO campaign and delivers consistent updates. We have a dedicated project manager; you will always be informed of our efforts to enhance your local presence.`,
      },
    ],
  },
  WHITE_LABEL_SEO: {
    pageHeader: {
      title: `Our Services`,
      subTitle: ``,
      tagline: `Operating a digital agency can be difficult, mainly when resources are scarce. Our white-label SEO services enable you to concentrate on your business operations while we manage the SEO.`,
    },
    first_section_icon: Icon1Image,
    first_section_heading: `Benefits of Employing a White-Label SEO Company`,
    first_section_content1: `A white-label digital marketing company usually offers services like SEO, content marketing, Google Ads, or Facebook ads that you can rebrand using your name or logo and sell to clients at an additional cost.`,
    first_section_content2: `White-label hiring services help your agency's digital marketing and SEO companies or consulting firms and permit you to deliver superior service, save time, and cut down on business expenses.`,
    second_section_heading: `Why Choose Ophanim for White-Label SEO Services?`,
    second_seaction_paragraph: `Our services are designed to provide solutions that unlock a new realm of opportunity, and we are recognized as one of the top White Label SEO companies.`,
    agencyData: [
      {
        icon: WorkFromHomeUserSofaStreamlineUltimate,
        heading: `Customized Software`,
        content: `We offer a diverse selection of customized software fully equipped with the requisite data to support your varied online campaigns, all seamlessly integrated into your dashboard.`,
      },
      {
        icon: StartupProductRocket,
        heading: `PPC`,
        content: `Ophanim possesses an extensive comprehension of PPC, and most SEO resellers anticipate its compatibility with the diverse complexities.`,
      },
      {
        icon: HumanResourcesNetwork,
        heading: `SEO Services`,
        content: `Our SEO services are designed to provide visibility and ranking, significantly distinguishing your business from the competition.`,
      },
      {
        icon: HumanResourcesWorkflow,
        heading: `Local SEO optimization`,
        content: `Our comprehensive White Label SEO program will make your business visible to nearby residents and businesses.`,
      },
      {
        icon: HumanResourcesNetwork,
        heading: `Concise Reporting`,
        content: `We are committed to providing our esteemed clients with precise and detailed reports that provide a comprehensive understanding of our operations.`,
      },
      {
        icon: HumanResourcesWorkflow,
        heading: `24X7 Support`,
        content: `At Ophanim, we maintain a team of exceptional professionals available 24/7 to assist you in resolving a wide range of issues.`,
      },
    ],
  },
  PAY_PER_CLICK: {
    pageHeader: {
      title: `The most effective PPC`,
      subTitle: `management company in the United States.`,
      tagline: `Gain immediate and consistent traffic and leads by implementing optimized advertising campaigns. Ophanim's Pay-per-click marketing services will enable you to achieve immediate visibility on Google Ads, even for the most competitive keywords in your industry.`,
    },
    first_section_icon: Icon1Image,
    first_section_heading: `Benefits of Employing a PPC Optimization Company`,
    first_section_content1: `The realm of paid ads continues to evolve. Because of this, it's challenging to keep up with the latest techniques. When you choose an agency specializing in PPC and PPC, they'll assist you in remaining on top of changes. They'll understand what's being done across various agencies and can incorporate those results for your campaign.`,
    first_section_content2: `Additionally, as the agency comprises a whole team that includes everyone, you'll be able to access various perspectives and knowledge. You'll have only one viewpoint if only one PPC director is on your internal team.`,
    second_section_heading: `Why Choose Ophanim for PPC Services?`,
    second_seaction_paragraph: `Since our inception, Ophanim has been committed to providing the most reliable and exceptional PPC services in the United States. We know the necessary steps to provide superior alternatives, and our solutions are designed to give you a good return on your investment in expanding and growing your business. You can always rely on us if you are searching for a reliable PPC company.NAs a result of our ability to create result-oriented and tactical PPC campaigns that align with the practical user experience, we are highly regarded by most of our clients.`,
    agencyData: [
      {
        icon: WorkFromHomeUserSofaStreamlineUltimate,
        heading: `Conversion-Oriented Tactics`,
        content: `Your sales channel and user experience meticulously devise our strategies. This assists us in eliminating obstacles and achieving your conversion objective.`,
      },
      {
        icon: StartupProductRocket,
        heading: `Distinct Marketing Strategies`,
        content: `As a digital marketing firm, we rely on the expertise of our consultants. They develop ingenious strategies that will further help you overcome numerous obstacles.`,
      },
      {
        icon: HumanResourcesNetwork,
        heading: `Factual Analysis`,
        content: `We optimize the website to increase your visibility and relevance by streamlining the process after thoroughly analyzing all aspects.`,
      },
      {
        icon: HumanResourcesWorkflow,
        heading: `Website Evaluation`,
        content: `Our objective is to assist you in determining the appropriate course of action by conducting consistent website audits that encompass traffic flow analysis and current conversion rates.`,
      },
      {
        icon: HumanResourcesNetwork,
        heading: `Customized Campaigns`,
        content: `A single possible pain can't be suitable for all clients. We ensure that each business design campaign's unique requirements are met.`,
      },
      {
        icon: HumanResourcesWorkflow,
        heading: `Utilizing Extensions`,
        content: `Extensions are excellent for engaging your target audience and converting their lucrative interest into sales. This, in turn, contributes to the success of your PPC campaign.`,
      },
    ],
  },
  CONTENT_MARKETING: {
    pageHeader: {
      title: `Our content marketing services effectively`,
      subTitle: `establish you as the industry leader.`,
      tagline: `Google presents visitors with the most pertinent content based on their search intent. This is why providing high-quality content relevant to your audience is crucial. To ensure that your sales funnel is successful and that your audience converts at each stage, it is essential to have SEO content that is relevant, helpful, and informative for your business.`,
    },
    first_section_icon: Icon1Image,
    first_section_heading: `Benefits of Employing a Content Marketing Company`,
    first_section_content1: `In this digital age, where attention spans are getting increasingly limited and online competition intense, companies recognize the significance of a successful content marketing plan. High-quality content has been the primary ingredient in profitable marketing campaigns. It allows companies to attract and engage with their intended viewers.`,
    first_section_content2: `While some companies might choose to navigate the challenging terrain of creating and distributing content independently, many have discovered the significant benefits of outsourcing to a professional company for marketing content.`,
    second_section_heading: `Why Choose Ophanim for Content Marketing Services in the USA?`,
    second_seaction_paragraph: `Our comprehensive content marketing services are designed to achieve your primary objective and increase your business's visibility to potential customers.`,
    agencyData: [
      {
        icon: WorkFromHomeUserSofaStreamlineUltimate,
        heading: `Establishment of a Blog`,
        content: `In addition to enhancing brand recognition, we strive to improve your search engine visibility by generating pertinent blog posts by our content team.`,
      },
      {
        icon: StartupProductRocket,
        heading: `White Papers and Case Studies`,
        content: `Our content team will develop captivating materials, including white papers and case studies, for your website to increase sales and revenue.`,
      },
      {
        icon: HumanResourcesNetwork,
        heading: `Infographics`,
        content: `Visual marketing is essential for attracting the appropriate audience, and our team will create exceptional infographics that will improve your brand's reputation.`,
      },
      {
        icon: HumanResourcesWorkflow,
        heading: `Email and Newsletter`,
        content: `Our email marketers provide a significant opportunity to generate and maintain leads, thereby fostering the desired loyalty from your potential customers.`,
      },
      {
        icon: HumanResourcesNetwork,
        heading: `Product Videos`,
        content: `By utilizing our exclusive video marketing services, your business can reach new audiences and overcome the broader communication barrier.`,
      },
      {
        icon: HumanResourcesWorkflow,
        heading: `Copywriting for the website`,
        content: `To achieve significant success in marketing initiatives, our content developers will generate compelling content that will be instrumental in the organization's branding.`,
      },
    ],
  },
};

export const servicesCategory = {
  SEO_SERVICES: "SEO_SERVICES",
  SMO_SERVICES: "SMO_SERVICES",
  ONLINE_REPUTATION: "ONLINE_REPUTATION",
  SMALL_BUSINESS_SEO: "SMALL_BUSINESS_SEO",
  ECOMMERCE_SEO: "ECOMMERCE_SEO",
  LOCAL_SEO_SERVICES: "LOCAL_SEO_SERVICES",
  WHITE_LABEL_SEO: "WHITE_LABEL_SEO",
  PAY_PER_CLICK: "PAY_PER_CLICK",
  CONTENT_MARKETING: "CONTENT_MARKETING",
};

export const newsCategory = {
  AI_IN_SEO: "AI_IN_SEO",
  LEADING_SEO_TRENDS: "LEADING_SEO_TRENDS",
  PPC_CAMPAIGN: "PPC_CAMPAIGN",
  PAY_PER_CLICK: "PAY_PER_CLICK",
};

export const NewsPagesData = {
  AI_IN_SEO: {
    image: AiArticleCoverImage,
    link: "/news/ai-in-seo",
    first: {
      heading:
        "Are the Latest SEO Updates Relevant With AI? Should You Invest or Pass?YouTube Optimization",
      paragraph1:
        "As we navigate the ever- evolving landscape of search engine optimization, it's crucial to stay ahead of the curve with the latest SEO updates. Google's algorithm updates in 2024 have significantly reshaped the playing field, making AI an indispensable tool for SEO professionals.",
      paragraph2:
        "AI-powered tools are now essential in analyzing vast amounts of data to identify emerging trends and user behavior patterns, allowing SEO strategists to make data-driven decisions faster than ever before. The most recent Google core updates have emphasized the importance of high-quality, relevant content that truly serves user intent.",
      paragraph3:
        "Let's jump straight into the topic to discuss how can AI be used in SEO. But, first, a question needs to be addressed:",
    },
    second: {
      heading: "Impact of AI on SEO Best Practices",
      paragraph1:
        "Google's core updates in 2024 have introduced several key changes. The latest Google SEO update emphasizes user experience, mobile-friendliness, and page speed more than ever before. Additionally, the Google algorithm update 2024 has placed increased importance on content quality and relevance, rewarding websites that provide comprehensive, authoritative information.",
      paragraph2:
        "The latest Google algorithm update also incorporates advanced AI and machine learning capabilities, allowing for more nuanced understanding of search intent and context. This means that keyword optimization, while still important, is no longer the sole determining factor in rankings.",

      listItems: [
        {
          text: "The latest Google algorithm update also incorporates advanced AI and machine learning capabilities, allowing for more nuanced understanding of search intent and context. This means that keyword optimization, while still important, is no longer the sole determining factor in rankings. Moreover, AI can enhance technical SEO aspects by identifying and resolving issues like broken links, slow loading times, and mobile responsiveness – all of which are prioritized in latest Google SEO updates. AI-driven chatbots and voice search optimization also cater to the latest SEO updates, improving user engagement and site visibility.",
        },
        {
          text: "Voice Search and Natural Language Processing: Voice search optimization has emerged as a key player in the latest SEO updates. As smart speakers and voice-activated devices become ubiquitous, optimizing for conversational queries and featured snippets has become essential.",
        },
        {
          text: "Semantic Search: The search engine can determine what you're looking for if you search for something. It takes into account your location, previous searches, and your intentions. To be visible in these search results, you must have relevant and valuable content to answer users' queries. Semantic Search is all about creating helpful content instead of using keywords as per the latest seo updates.",
        },
        {
          text: "Mobile-First Indexing: Mobile-first indexing has reached new heights in the latest Google SEO update. With the majority of searches now occurring on mobile devices, optimizing for mobile has become non-negotiable. In the latest seo updates, Page speed, responsive design, and mobile-friendly content are more crucial than ever.",
        },
        {
          text: "Search Generative Experience (SGE): Google's Search Generative Experience (SGE) is changing user behavior by providing AI-generated results at the top of search results pages (SERPs). SGE offers instant answers, images, and chat options, enhancing user experience. As per the latest seo updates, websites must adapt to optimize for SGE. The goal is to provide easily understandable answers with visuals and prompt suggestions. SGE acts more like a conversational AI assistant than a search engine, potentially shaping the future of SERPs. However, full integration and disruption of traditional search methods by SGE will take time.",
        },
        {
          text: "Zero-Click Searches: Zero-Click Search is when you are searching something and answers appear without you even complete writing your query. Problem is, there has been a rise in such search results and Google is providing more and more info in SERPs as a result. When SERPs are giving information directly, it reducing clicks and affects businesses by reducing traffic. As per the latest seo updates, there is a 57% increase in Zero-Click searches which is a nightmare to businesses trying to grow organic traffic with click rates. You can minimize the impact of Zero-Click Searches by Optimizing Featured Snippets, Creating Unique Meta Descriptions, and Diversifying Traffic Sources.",
        },
        {
          text: "Experience-Expertise-Authoritativeness-Trustworthiness(EEAT): The E-E-A-T framework is crucial in SEO for higher rankings and organic traffic: Google values expertise, authority, and trust. But in 2023, AI influenced content creation and search algorithms, creating an AI-generated content saturation leading to a scarcity of high-quality user-based content, prompting Google to raise stricter ranking criteria. To establish expertise in your field, identify your niche, conduct keyword research, create high-quality content, and build backlinks. Focus on audience needs, use facts, trends, and customer feedback in content, and collaborate with others for exposure. The ultimate goal is to become a go-to source, increase visibility, and grow your presence by consistently sharing valuable content.",
        },
      ],
    },
    third: {
      heading: "Conclusion",
      paragraph1:
        "By leveraging AI in your SEO efforts, you're not just keeping up with latest SEO updates; you're future-proofing your digital presence for years to come. Embrace AI-driven SEO strategies now to stay competitive and ensure your content remains visible in an increasingly crowded digital space.",
      paragraph2:
        "Want the latest SEO services for your company? Ophanim Technologies can help you with all your SEO plans, help you outrank your competition, and boost traffic on your website. Connect with us and get every service you need.",
    },
  },
  LEADING_SEO_TRENDS: {
    image: LocalSeoTrends,
    link: "/news/leading-seo-trends",
    first: {
      heading: "Are The Latest SEO Updates Relevant to Your Website Strategy?",
      paragraph1:
        "The SEO landscape continues to evolve rapidly as we head towards the end of 2024. Google's latest SEO updates have significantly reshaped how we approach search engine optimization. The most recent Google core updates in 2024 have greatly emphasized user experience, content quality, and page performance.",
      paragraph2:
        "So, what is the latest update for SEO in 2024? What are the challenges and changes, and how will they change the business landscape adhering to Google algorithm updates? Let’s look into all these crucial questions starting with-",
    },
    second: {
      heading: "Should Companies Invest in the Latest SEO Updates?",
      paragraph1:
        "The digital landscape is constantly evolving, and staying ahead of the curve is crucial for online success. With the latest SEO updates rolling out, businesses simply can't afford to ignore this vital aspect of their digital strategy. Google's latest SEO update has emphasized the importance of user experience and high-quality content.",
      paragraph2:
        "This shift means that companies need to allocate more resources to create valuable, engaging content that aligns with user intent and improve their search engine rankings. This includes optimizing for mobile devices, improving page load speeds, and focusing on local SEO for businesses with physical locations. Companies that recognize the value of organic search traffic and adapt to the latest SEO updates will be well-positioned to outperform their competitors and capture a larger share of their target market.",
      paragraph3:
        "The SEO update 2024 is expected to further refine these requirements, making it essential for businesses to adapt quickly.",
    },
    third: {
      heading: "Top Benefits to Invest in Latest SEO Update 2024",
      listItems: [
        {
          text: "Adapting to Algorithm Changes: With Google's constant updates, including the latest seo updates and AI-driven improvements, staying on top of SEO ensures your website remains visible and relevant.",
        },
        {
          text: "Voice Search Optimization: As voice-activated devices become more prevalent, optimizing for conversational queries will give you a competitive edge.",
        },
        {
          text: "Mobile-First Indexing: With mobile usage surpassing desktop, prioritizing mobile SEO is no longer optional but essential for success.",
        },
        {
          text: "Enhanced User Experience: SEO best practices align with providing a superior user experience, which not only pleases search engines but also your visitors.",
        },
        {
          text: "Local SEO Dominance: For businesses with physical locations, local SEO continues to be a game-changer in attracting nearby customers.",
        },
        {
          text: "Content Quality Over Quantity: The latest SEO updates emphasize high-quality, authoritative content, rewarding businesses that invest in valuable information.",
        },
        {
          text: "Improved E-E-A-T Signals: Experience, Expertise, Authoritativeness, and Trustworthiness are more critical than ever with latest SEO updates, especially for YMYL (Your Money or Your Life) sites.",
        },
        {
          text: "Faster Loading Times: Speed is a crucial ranking factor, and investing in SEO best practices includes optimizing your site's performance.",
        },
        {
          text: "Structured Data Opportunities: Leveraging schema markup can help your site stand out in search results with rich snippets.",
        },
        {
          text: "Long-Term Cost-Effectiveness: Unlike paid advertising, latest SEO updates provide lasting results, making it a cost-effective long-term strategy.",
        },
        {
          text: "By staying committed to SEO best practices in 2024, you're not just keeping up—you're setting yourself up for sustainable online success.",
        },
      ],
    },
    fourth: {
      heading: "Conclusion",
      paragraph1:
        "To stay ahead in this ever-changing landscape, it's crucial to keep abreast of the latest Google algorithm updates and adjust your strategies accordingly. Remember, the key to SEO success in 2024 and beyond is creating high-quality, user-centric content while adhering to the latest technical SEO guidelines.",
      paragraph2:
        "If you need any help with your SEO strategies and want a high-yield content marketing strategy that adheres to the latest SEO updates and market trends, contact us. Ophanim’s team would love to work with you.",
    },
  },
  PPC_CAMPAIGN: {
    image: PpcCampaignCover,
    link: "/news/ppc-campaign",
    first: {
      heading: "Strategies to Create a PPC Campaign",
      paragraph1:
        "Understanding the true meaning of PPC marketing is crucial for any business looking to thrive in the digital age. More than 55% of companies in the US run ads, but they hardly get any results because they are either ignorant about PPC or do not utilise it properly. A PPC marketing campaign is not just about placing ads; it's about strategic placement, targeted messaging, and continuous optimization.",
      paragraph2:
        "That's where a dedicated PPC company provides comprehensive PPC advertising solutions tailored to your unique needs. Still, as a business, you need at least a basic understanding of PPC and its utilisation. This article will show you how to run a successful PPC advertising campaign. Let’s get started.",
    },
    second: {
      heading: "Best Practices for a Quality PPC Strategy",
      paragraph1:
        "We didn't want to permit you to use your hard-earned cash on advertising without a set of best guidelines to follow, Did you? You wouldn't. We'd like to ensure you're successful in the subsequent PPC campaign.",
      paragraph2:
        "Let's look at some PPC strategies to assist you in maximizing your time and budget. For reference, we will focus on the paid search ads (those tiny ads you find on the search engine) in this article.",
    },
    third: {
      heading: "Create strong PPC advertising copy",
      paragraph1:
        "By bidding on targeted keywords, you will place your advertisement in front of the correct individuals, and a well-written advertising copy can get users to click the ad.",
      paragraph2:
        "As with your keyword advertising, your ads must satisfy the needs of the person searching, convey precisely what they're seeking, and ensure that it is evident in your chosen language.",
    },
    fourth: {
      heading: "Optimize your landing page",
      paragraph1:
        "Perhaps the most crucial component of PPC (after the advertisement text) will be the website to which you direct leads once they have clicked on the advertisement.",
      paragraph2:
        "The page must be specifically targeted and pertinent to the ad you're promoting. It must also provide the promises made and a smooth journey.",
      paragraph3:
        "To achieve results, make sure you create a compelling headline with clean design, desired keywords, and eye catching CTA button. Do an A/B testing to pick the most effective ones.",
    },
    fifth: {
      heading: "Test your A/B on PPC advertisements",
      paragraph1:
        "Testing an ad's effectiveness aims to improve the click-through and conversion rates. It's good that advertisements are comprised of only four components, which you'll have to examine: headline, description, landing page, and words.",
      paragraph2:
        "A minor tweak to any of these components could significantly impact your outcomes. Therefore, changing one thing at a given time is essential to ensure you know the source of improvement.",
      paragraph3:
        "Last but not least, permit your advertisements to last long enough for you to collect relevant data and test them early to ensure that you don't spend the money you've spent on a subpar advertisement.",
      paragraph4: "Increase the value of your investment.",
      paragraph5:
        " In the end, the best way to maximise ROI from the ads you run is by taking into account the value of a customer's lifetime and acquisition expenses, which can aid you in determining how much you can afford to spend on the prospect of a new customer and what percentage of it comes via paid ads.",
      paragraph6:
        "To be more specific, it is necessary to define outputs and inputs.",
      paragraph7: " That means,",
      listItems: [
        { text: "lower your input, a.k.a. cost per lead (CPL), and" },
        { text: "improve the amount you earn (revenue)." },
      ],
    },
    sixth: {
      heading: "Conclusion",
      paragraph1:
        "It's easy to get started with PPC advertisements. However, to increase the efficacy of your marketing campaigns, it is essential to have the best data. Practical tools can help. Through advertising research, you can find competitors in paid search, analyze strategies used by competitors, analyze strategies, and much more.",
      paragraph2:
        "If you need help creating a PPC marketing campaign, connect with us here.",
    },
  },
  PAY_PER_CLICK: {
    image: PayPerClickCover,
    link: "/news/pay-per-click",
    first: {
      heading: "What is PPC Marketing",
      paragraph1:
        "PPC marketing is a powerful digital advertising strategy that can revolutionize your online presence.PPC is a cost-effective solution where you only pay when someone clicks on your ads. A professional PPC agency can help you navigate this complex landscape, offering expert PPC management services to maximize your return on investment..",
    },
    second: {
      heading: "PPC Marketing",
      paragraph1:
        "PPC full form stands for Pay-per-click marketing. It is an online advertising platform where advertisers pay when their users click on their advertisements. Advertisers bid a click's value by considering the platform, keywords, and audiences. Paid search marketing and PPC management are incredibly effective forms of digital marketing. If you implement the right pay-per-click strategy, you will experience massive results.",
      paragraph2:
        "Here is an example of PPC. If you search ( men shirts), these sponsored ads you see when you search google, are PPC ads.",
      paragragh3:
        "A company can use a PPC marketing campaign for a variety of goals, including-",
      listItems: [
        { text: "Generating Leads" },
        { text: "Increasing Sales" },
        { text: "Brand Awareness" },
      ],
      paragraph5:
        "When it's done correctly, PPC can earn you good leads. If you can create seamless user experiences, this could result in massive ROI on your PPC initiatives. You can even partner with a reputable PPC services provider to gain access to cutting-edge tools and industry insights that can propel your campaigns to new heights.",
      paragraph6:
        "From keyword research to ad creation and performance tracking, professional PPC management services ensure every aspect of your campaign is fine-tuned for success.",
    },
    third: {
      heading: "How Does PPC Advertising Work?",
      paragraph1:
        "Pay-per-click, or PPC, is a form of advertising paid for that is part of the umbrella of search engine marketing (SEM). In PPC, advertisers are only paid when users interact by engaging with their advertisement through impressions or clicks.",
      paragraph2:
        "One of the most significant Google updates today focuses on user experience and content quality. The latest Google SEO update emphasizes the importance of Core Web Vitals, which measure page loading speed, interactivity, and visual stability. Websites that prioritize these factors are likely to see improved rankings.",
      paragraph3:
        "Pay-per-click advertising is an incredibly straightforward procedure:",
      listItems: [
        {
          text: "Register for an advertising account through Google Ads or the platform on which you wish to display ads.",
        },
        {
          text: "Choose who you wish to focus on based on the keywords and other factors, such as demographics.",
        },
        {
          text: "Ensure you have a budget and the price you'll pay per click.",
        },
        { text: "Design the copy, images and videos for your ad." },
        {
          text: "Place your advertisement in an auction alongside other bidders bidding for the exact keywords or specific criteria for targeting.",
        },
        {
          text: "Watch for auctions to decide which ads will be displayed and where",
        },
        { text: "You pay when there is a click on your advertisement." },
      ],
      paragraph5:
        "There are various platforms and formats for ads (more about them in the future)—and even different strategies. The fundamentals remain.",
    },
    fourth: {
      heading: "Conclusion",
      paragraph1:
        "Don't let your competitors outrank you in this digital race. Embrace the power of PPC marketing today and watch your business soar to new heights in the online marketplace.",
      paragraph2:
        "You can connect with us to get help with PPC management and campaign strategies. Ophanim has a proven track record of successful PPC marketing campaigns and has helped numerous businesses succeed.",
    },
  },
};
